import React from 'react';
import { useInView } from 'react-intersection-observer';
import { cn, Heading, Text, Superscript } from '@superside/ui';
import { Box } from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { ProgressiveModalEnabledCta } from '../../components/HeroCtaGroup';
import { PageSection } from '../../components/PageSection';
import { type IVisualAsset, MediaTypes } from '../../components/VisualAsset';
import { SectionButton } from '../../components/SectionButton';
import { type ButtonProps, buttonsBox, tertiaryLink } from '../HeroLeft';
import { type CtaButtonType, type CtaProps } from '../../types/ctaButton';
import { Caas } from '../../components/Caas';
import styles from './index.module.css';
import { MediaBox } from './MediaBox';

interface ContentGridSnippetProps {
  contentImage?: any;
  inlineVideo?: string;
  iframeVideo?: string;
  wistiaVideoId?: string;
  reverse?: boolean;
  descriptionRichText?: { content: Array<any> };
  contentText?: string;
  mediaType?: 'image' | 'inlineVideo' | 'iframeVideo' | 'wistiaVideo';
  headerText?: string;
  headerRichText?: { content: Array<any> };
  isH1Header?: boolean;
  withButtons?: boolean;
  button?: ButtonProps;
  tertiaryButton?: ButtonProps;
  primaryCtaButton?: CtaProps;
  tertiaryCtaButton?: CtaButtonType;
  superscriptText?: string;
  asset?: IVisualAsset;
  isLight?: boolean;
}

export const ContentGridSnippet: React.FC<ContentGridSnippetProps> = (props) => {
  const {
    descriptionRichText,
    contentText,
    headerText,
    headerRichText,
    reverse,
    withButtons,
    button,
    tertiaryButton,
    primaryCtaButton,
    tertiaryCtaButton,
    isH1Header = false,
    asset,
    superscriptText,
    ...rest
  } = props;

  const [ref, isVisible] = useInView({ rootMargin: '100px 0px', triggerOnce: true });

  const withPrimaryCtaButton =
    primaryCtaButton &&
    Boolean(
      (primaryCtaButton.ctaText && primaryCtaButton.ctaUrl) || primaryCtaButton.predefinedForm
    );
  const withTertiaryCtaButton =
    tertiaryCtaButton && Boolean(tertiaryCtaButton.ctaText && tertiaryCtaButton.ctaUrl);
  const withPrimaryButton = button && Boolean(button.ctaText && button.ctaLink); // DEPRECATED
  const withTertiaryButton =
    tertiaryButton && Boolean(tertiaryButton.ctaText && tertiaryButton.ctaLink); // DEPRECATED

  const hasDefinedButtons =
    withPrimaryCtaButton || withTertiaryCtaButton || withPrimaryButton || withTertiaryButton;

  const isCaas = asset?.mediaType === MediaTypes.CAAS;
  const isNotYoutubeVideo = asset?.mediaType !== MediaTypes.YOUTUBE;

  const hasRteNoHeader = descriptionRichText && !(headerText || headerRichText);
  const hasRteWithHeader = descriptionRichText && (headerText || headerRichText);

  return (
    <>
      <PageSection
        unlimited
        style={{
          overflow: 'hidden'
        }}
        {...rest}
      >
        <PageSection paddingTop='none' paddingBottom='none' {...rest}>
          <Box
            className={cn(
              '1024:flex-row 1024:gap-0 1024:items-center 1024:justify-between',
              reverse && '1024:flex-row-reverse'
            )}
          >
            <Box
              ref={ref}
              className={cn(
                '1024:w-[calc(50%_-_39px)] 1440:w-[calc(50%_-_69px)] mb-6 rounded-lg',
                isCaas && 'hidden',
                isNotYoutubeVideo && 'overflow-hidden'
              )}
            >
              <MediaBox isVisible={isVisible} {...props} />
            </Box>
            <Box
              className={`${styles.contentBox} 1024:w-[calc(50%_-_39px)] 1650:w-[calc(50%_-_69px)] 768:w-[90%] [&_ul]:list-disc [&_ul_li]:mt-4 [&_ul_li]:list-item`}
            >
              {superscriptText ? <Superscript>{superscriptText}</Superscript> : null}
              {headerRichText ? (
                <SanityBlockComponent
                  blocks={headerRichText}
                  renderNormalAsHeading={{ as: 'h2', level: isH1Header ? 'h3' : 'h5' }}
                />
              ) : headerText ? (
                <Heading as='h2' level={isH1Header ? 'h3' : 'h5'}>
                  {headerText}
                </Heading>
              ) : null}

              {hasRteNoHeader ? (
                <Text size='lg'>
                  <SanityBlockComponent blocks={descriptionRichText} />
                </Text>
              ) : hasRteWithHeader ? (
                <Text className='mt-3'>
                  <SanityBlockComponent blocks={descriptionRichText} />
                </Text>
              ) : contentText ? (
                <Text>{contentText}</Text>
              ) : null}

              {withButtons && hasDefinedButtons ? (
                <Box className={buttonsBox} margin={{ top: 'large' }} gap='8'>
                  {withPrimaryCtaButton ? (
                    <ProgressiveModalEnabledCta cta={primaryCtaButton} />
                  ) : withPrimaryButton ? (
                    <SectionButton primary href={button.ctaLink} label={button.ctaText} />
                  ) : null}

                  {withTertiaryCtaButton ? (
                    <SectionButton
                      className={tertiaryLink}
                      plain
                      href={tertiaryCtaButton.ctaUrl}
                      label={tertiaryCtaButton.ctaText}
                      isTargetBlank={tertiaryCtaButton.isTargetBlank}
                      hasNoReferrer={tertiaryCtaButton.hasNoReferrer}
                    />
                  ) : withTertiaryButton ? (
                    <SectionButton
                      className={tertiaryLink}
                      plain
                      href={tertiaryButton.ctaLink}
                      label={tertiaryButton.ctaText}
                    />
                  ) : null}
                </Box>
              ) : null}
            </Box>
            {isCaas && <Caas timeouts={asset?.caasTimeoutRanges} />}
          </Box>
        </PageSection>
      </PageSection>
    </>
  );
};

ContentGridSnippet.displayName = 'ContentGridSnippet';

export default ContentGridSnippet;
